<template>
  <div class="LoginLog">
    <div class="topinput">
      <a-select
        style="width: 165px"
        v-model:value="dept_id"
        allowClear
        placeholder="请选择部门"
      >
        <a-select-option
          :value="item.dept_id"
          v-for="item in deptList"
          :key="item.dept_id"
          >{{ item.dname }}</a-select-option
        >
      </a-select>
      <a-date-picker
        v-model:value="mdate"
        @change="dateChange"
        placeholder="登入日期"
      />
      <a-button type="primary" @click="search">
        <template #icon><SearchOutlined /></template>
        搜索
      </a-button>
    </div>
    <div class="bottomtable">
      <!-- <div class="tabletop">
        <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent>
            <a-button type="primary">
              导出
              <DownOutlined />
            </a-button>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a href="javascript:;">导出Excel</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div> -->
      <a-table
        :columns="columns"
        :data-source="list"
        :pagination="{
          current: page,
          total: total,
        }"
        rowKey="id"
        @change="pageChange"
      >
      </a-table>
    </div>
  </div>
</template>
<script>
import { SearchOutlined } from "@ant-design/icons-vue";
import { getLoginLog } from "../../service/operationlog";
import { onMounted, reactive, toRefs } from "@vue/runtime-core";
const columns = [
  {
    title: "序号",
    dataIndex: "id",
  },
  {
    title: "日期",
    dataIndex: "dtime",
  },
  {
    title: "用户名",
    dataIndex: "user",
  },
  {
    title: "部门",
    dataIndex: "dname",
  },
  {
    title: "首次登入时间",
    dataIndex: "ftime",
  },
  {
    title: "登入次数",
    dataIndex: "num",
  },
];

export default {
  name: "LoginLog",
  setup() {
    const state = reactive({
      list: [],
      total: undefined,
      deptList: [],
    });
    const params = reactive({
      page: 1,
      count: 10,
      dept_id: undefined,
      mdate: "",
    });
    onMounted(() => {
      init();
    });
    const init = async () => {
      const res = await getLoginLog({
        access_token: sessionStorage.getItem("token"),
        ...params,
      });
      console.log(res);
      state.list = res.data.data.list;
      state.total = res.data.data.total;
      state.deptList = res.data.data.deptList;
    };
     const dateChange = (date, dateString) => {
      console.log(date, dateString);
      params.mdate = dateString;
      // init();
    };
    const pageChange = (e) => {
      // console.log(e);
      params.page = e.current;
      init();
    };
    const search = () => {
      console.log(params);
      init();
    };
    return { columns, ...toRefs(state), ...toRefs(params), pageChange, search,dateChange };
  },
  components: { SearchOutlined },
};
</script>
<style  scoped>
.LoginLog {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.topinput {
  border-bottom: 1px solid #f7f7f7;
  padding: 20px;
  margin-bottom: 10px;
}
.ant-calendar-picker,
.ant-select {
  margin-right: 20px;
}
.bottomtable {
  padding: 0 10px;
}
.tabletop {
  text-align: right;
  margin-bottom: 10px;
}
</style>